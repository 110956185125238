import React from "react";
import { Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import "./productspage.styles.scss";
import CustomDrawer from "../../components/drawer/drawer.component";
import ProductComponent from "../../components/products/product.component";

const ProductPage = () => {
  let { section } = useParams();

  return (
    <div className="product">
      <CustomDrawer section={"products"} />
      <Divider orientation="vertical" className="divider" />
      <div className="component">
        <ProductComponent />
      </div>
    </div>
  );
};

export default ProductPage;

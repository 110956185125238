import React, { useState } from "react";
import { Paper, Button } from "@mui/material";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

import "./landing.styles.scss";

import InputTextField from "../../components/input-text-field/input-text-field.component";
import { generalToastStyle } from "../../utils/toast.styles";

const Landing = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const onLogin = () => {
    if (email === "" || typeof email === "undefined") {
      toast.warn("Email is required!", generalToastStyle);
    } else if (password === "" || typeof password === "undefined") {
      toast.warn("Password is required!", generalToastStyle);
    } else {
      let formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);

      axios
        .post(
          "https://api.sadashrijewelkart.com/v1.0.0/admin/login.php",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          localStorage.setItem("admin", JSON.stringify(response.data.response));

          navigate("/home");
        })
        .catch((err) => {
          toast.error(err.response.data.message, generalToastStyle);
        });
    }
  };

  return (
    <div className="landing">
      <ToastContainer />
      <img
        alt="logo"
        src={process.env.PUBLIC_URL + "/assets/logo_white.png"}
        height={50}
      />
      <Paper className="sheet">
        <div className="title">Admin Login</div>
        <InputTextField
          title="Email"
          value={email}
          onEdit={(e) => setEmail(e.target.value)}
          type={"email"}
        />
        <InputTextField
          title="Password"
          value={password}
          onEdit={(e) => setPassword(e.target.value)}
          type={"password"}
        />
        <Button className="button" onClick={onLogin}>
          Login
        </Button>
      </Paper>
    </div>
  );
};

export default Landing;

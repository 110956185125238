import CustomDrawer from "../../components/drawer/drawer.component";
import OrderDetail from "../../components/orderDetail/orderDetail.component";

const OrderDetailsComponent = () => {
  return (
    <div className="product">
      <CustomDrawer section={"orders"} />
      <div className="component">
        <OrderDetail />
      </div>
    </div>
  );
};

export default OrderDetailsComponent;

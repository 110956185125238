import React from "react";
import { Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import "./categoriespage.styles.scss";
import CustomDrawer from "../../components/drawer/drawer.component";
import CategoryComponent from "../../components/categories/categories.component";

const CategoryPage = () => {
  let { section } = useParams();

  return (
    <div className="category">
      <CustomDrawer section={"categories"} />
      <Divider orientation="vertical" className="divider" />
      <div className="component">
        <CategoryComponent />
      </div>
    </div>
  );
};

export default CategoryPage;
import React from "react";
import "./item.styles.scss";

const DrawerItem = ({
  title,
  value,
  icon,
  arrowicon,
  section,
  clickAction,
  hassubcategory,
  isSelected,
  isaSubcategory
}) => {
  return (
    <div
      className={`drawer-item ${isSelected ? 'drawer-item-selected' : ''} ${isaSubcategory ? 'sub-category' : ''}`}
      onClick={clickAction}
    >
      <div className="icon">{icon}</div>
      <div className="title">{title}</div>

      {hassubcategory === true ? (
        <div className="arrow">
          {arrowicon}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DrawerItem;

import { React, useState } from "react";
import { Divider } from "@mui/material";
import { Dashboard, Business, List, Shop, Category } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import "./drawer.styles.scss";

import DrawerItem from "./item/item.component";

const CustomDrawer = ({ section }) => {
  const navigate = useNavigate();
  const admin = JSON.parse(localStorage.getItem("admin"));
  const imageUrl = admin["image"]
    ? `https://api.sadashrijewelkart.com/${admin["image"]}`
    : process.env.PUBLIC_URL + "/assets/logoNew.png";

  const isSubcategorySelected =
    section === "categories" ||
    section === "products" ||
    section === "reviews" ||
    section === "orders";
  const [showSubCategories, setShowSubCategories] = useState(
    isSubcategorySelected
  );

  const handleCategoriesClick = () => {
    setShowSubCategories(!showSubCategories);
    if (section !== "categories") {
      navigate("/categories");
    }
  };

  return (
    <div className="drawer-component">
      <div className="data">
        <img
          alt="logo"
          src={process.env.PUBLIC_URL + "/assets/logo_dark.png"}
          className="logo"
        />
        <Divider />
        <div className="drawer-content">
          <img alt="profile" className="profile" src={imageUrl} />
          <div className="name">{admin["name"]}</div>
          <div className="edit-option">Edit</div>
        </div>
        <DrawerItem
          title="Dashboard"
          value="dashboard"
          icon={<Dashboard />}
          section={section}
          clickAction={() => {
            if (section !== "dashboard") navigate("/home");
          }}
          isSelected={section === "dashboard"}
        />
        <DrawerItem
          title="Organizations"
          value="organizations"
          icon={<Business />}
          section={section}
          clickAction={() => {
            if (section !== "organizations") navigate("/organizations");
          }}
          isSelected={section === "organizations"}
        />
        <DrawerItem
          title="Shops"
          value="shops"
          icon={<Shop />}
          arrowicon={
            showSubCategories ? <ExpandLessIcon /> : <ChevronRightIcon />
          }
          section={section}
          clickAction={handleCategoriesClick}
          hassubcategory={true}
          isSelected={isSubcategorySelected}
        />
        {showSubCategories && (
          <div className="sub-categories">
            <DrawerItem
              title="Categories"
              value="categories"
              icon={<List />}
              section={section}
              clickAction={() => {
                if (section !== "categories") navigate("/categories");
              }}
              isaSubcategory={true}
              isSelected={section === "categories"}
            />
            <DrawerItem
              title="Products"
              value="products"
              icon={<Category />}
              section={section}
              clickAction={() => {
                if (section !== "products") navigate("/products");
              }}
              isaSubcategory={true}
              isSelected={section === "products"}
            />
            <DrawerItem
              title="Reviews"
              value="reviews"
              icon={<Category />}
              section={section}
              clickAction={() => {
                if (section !== "reviews") navigate("/reviews");
              }}
              isaSubcategory={true}
              isSelected={section === "reviews"}
            />
            <DrawerItem
              title="Orders"
              value="orders"
              icon={<Category />}
              section={section}
              clickAction={() => {
                if (section !== "orders") navigate("/orders");
              }}
              isaSubcategory={true}
              isSelected={section === "orders"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomDrawer;

import { Routes, Route, BrowserRouter } from "react-router-dom";

import "./App.css";

import Landing from "./pages/landing/landing.component";
import Home from "./pages/home/home.component";
import OrganizationPage from "./pages/organization/organizationpage.component";
import CategoryPage from "./pages/categories/categoriespage.component";
import ProductPage from "./pages/products/productspage.component";
import ReviewsPage from "./pages/reviews/reviewsPage.component";
import OrdersPage from "./pages/orders/ordersPage.component";
import OrderDetailsComponent from "./pages/orderDetails/orderDetails.component";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route path="/home" element={<Home />} />
          <Route path="/organizations" element={<OrganizationPage />} />
          <Route path="/categories" element={<CategoryPage />} />
          <Route path="/products" element={<ProductPage />} />
          <Route path="/reviews" element={<ReviewsPage />} />
          <Route path="/orders" element={<OrdersPage />} />
          <Route path="/order-detail" element={<OrderDetailsComponent />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, { Fragment, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Divider,
  Paper,
  TableContainer,
  Avatar,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  createTheme,
  ThemeProvider,
  CircularProgress,
  SwipeableDrawer,
  Button,
  IconButton,
  Box,
  Typography,
  Rating,
  ImageListItem,
  ImageList,
  Grid,
  Checkbox,
} from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import {
  Search,
  Edit,
  Delete,
  Add,
  AddAPhoto,
  Done,
  Restore,
  KeyboardArrowDown,
  KeyboardArrowUp,
  CheckBox,
} from "@mui/icons-material";
import axios from "axios";

import "./orders.styles.scss";

import InputTextField from "../input-text-field/input-text-field.component";
import { generalToastStyle } from "../../utils/toast.styles";
import MetricBoxComponent from "./metricBox.component";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#a36e29",
    },
  },
  typography: {
    fontFamily: '"Work Sans", sans-serif',
  },
});

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const mockOrders = [
  {
    id: "SS10922",
    customerName: "Sushovan Paul",
    orderDate: "2024-03-12 at 04:18",
    itemName: "Margaret Gold Ring, 16mm",
    price: "Rs: 16,777",
    orderStatus: "Unfulfilled",
  },
  {
    id: "KS10922",
    customerName: "Paul Sushovan",
    orderDate: "2024-03-12 at 04:18",
    itemName: "Margaret Gold Ring, 16mm",
    price: "Rs: 26,777",
    orderStatus: "Fulfilled",
  },
];

const OrdersComponent = ({ row }) => {
  const navigate = useNavigate();
  const admin = JSON.parse(localStorage.getItem("admin"));
  const [searchText, setSearchText] = useState();

  const [newCategoryImage, setNewCategoryImage] = useState();
  const [newCategoryName, setNewCategoryName] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const [drawerState, setDrawerState] = useState(1);
  const [displaySubcategories, setDisplayCategories] = useState([]);

  const [categories, setCategories] = useState(null);
  const [page, setPage] = useState(0);
  const [categoryClicked, setCategoryClicked] = useState(0);
  const [subCategoryClicked, setSubCategoryClicked] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showDrawer, setShowDrawer] = useState(false);

  const getCategories = () => {
    axios
      .get(
        "https://api.sadashrijewelkart.com/v1.0.0/admin/product/category/all.php",
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setCategories(response.data.response);
      })
      .catch((error) => {
        console.log(error);
        toast.warn(error.response.data.message, generalToastStyle);
      });
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rowClicked = (row) => {
    setCategoryClicked(categories.indexOf(row));
    setShowDrawer(true);
  };

  // Delete a category
  const deleteCategory = (id) => {
    console.log(`Bearer ${admin.token}`);
    axios
      .delete(
        "https://api.sadashrijewelkart.com/v1.0.0/admin/product/category/delete.php",
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
            "Content-Type": "application/json",
          },
          data: {
            type: "category",
            id,
          },
        }
      )
      .then((_) => {
        getCategories();
        toast("Category deleted successfully!", generalToastStyle);
      })
      .catch((err) => {
        setShowLoading(false);
        toast.error(err.response.data.message, generalToastStyle);
      });
  };

  // Delete a sub category
  const deleteSubCategory = (id) => {
    console.log(`Bearer ${admin.token}`);
    axios
      .delete(
        "https://api.sadashrijewelkart.com/v1.0.0/admin/product/category/delete.php",
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
            "Content-Type": "application/json",
          },
          data: {
            type: "sub-category",
            id,
          },
        }
      )
      .then((_) => {
        getCategories();
        toast("Sub Category deleted successfully!", generalToastStyle);
      })
      .catch((err) => {
        setShowLoading(false);
        toast.error(err.response.data.message, generalToastStyle);
      });
  };

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="category-component">
      <ToastContainer />

      {/* Swipable Drawer */}
      <ThemeProvider theme={theme}>
        <SwipeableDrawer
          anchor="right"
          open={showDrawer}
          onClose={() => {
            setShowLoading(false);

            setShowDrawer(false);
          }}
          onOpen={() => setShowDrawer(true)}
        >
          <div
            style={{
              padding: "20px",
              marginTop: "20px",
              marginLeft: "20px",
            }}
          >
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "600",
                fontFamily: '"Work Sans", sans-serif',
                marginBottom: "40px",
              }}
            >
              View User Review
            </div>
            <Box
              style={{
                maxWidth: "30vw",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "2%",
                }}
              >
                <Avatar sx={{ bgcolor: "gray" }} alt="Gemy Sharp">
                  G
                </Avatar>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    marginLeft: "2%",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#606060",
                      fontSize: "1rem",
                    }}
                  >
                    Gordon Ramsey
                  </Typography>
                  <Typography style={{ color: "#a0a0a0" }}>
                    12 March 2014
                  </Typography>
                </Box>
              </Box>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Rating name="size-large" value={4} size="large" readOnly />
                <Typography style={{ fontSize: "1.4rem" }}>4.5/5</Typography>
              </Box>
              <Typography
                variant="subtitle"
                style={{
                  textAlign: "justify",
                  marginTop: "10%",
                  marginBottom: "5%",
                }}
              >
                Receive ₹200 worth xCLusive Points by writing a review and
                uploading a picture of your jewellery
              </Typography>

              <ImageList
                sx={{ width: "100%", height: 400 }}
                variant="quilted"
                cols={4}
                rowHeight={121}
              >
                {itemData.map((item) => (
                  <ImageListItem
                    key={item.img}
                    cols={item.cols || 1}
                    rows={item.rows || 1}
                  >
                    <img
                      {...srcset(item.img, 121, item.rows, item.cols)}
                      alt={item.title}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
              <IconButton>
                <Edit
                  className="allow"
                  onClick={() => {
                    setDrawerState(2);
                    setShowDrawer(true);
                  }}
                />
              </IconButton>
              <Delete
                className="delete"
                onClick={() => deleteCategory(row.id)}
              />
            </Box>
          </div>
        </SwipeableDrawer>
      </ThemeProvider>

      {/* Main Content */}
      <div className="head">
        <div className="head-txt">Orders</div>
      </div>

      <div className="secondary-div">
        <div className="secondary-content"></div>
        {/* <div class="input-container">
          <Search class="icon" />
          <input
            class="input-field"
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
          />
        </div> */}
      </div>
      <Grid
        container
        spacing={3}
        style={{
          width: "100%",
          height: "300px",
          paddingLeft: "25px",
          marginBottom: "25px",
        }}
      >
        <Grid item xs={12 / 5}>
          <MetricBoxComponent heading={"Total Orders"} metric={"16"} />
        </Grid>
        <Grid item xs={12 / 5}>
          <MetricBoxComponent heading={"Total Sales"} metric={"16.4K"} />
        </Grid>
        <Grid item xs={12 / 5}>
          <MetricBoxComponent heading={"Orders In Progress"} metric={"4"} />
        </Grid>
        <Grid item xs={12 / 5}>
          <MetricBoxComponent heading={"Orders Completed"} metric={"80"} />
        </Grid>
        <Grid item xs={12 / 5}>
          <MetricBoxComponent heading={"Orders Refunded"} metric={"2"} />
        </Grid>
      </Grid>
      <Divider />

      <ThemeProvider theme={theme}>
        <Paper className="table-paper">
          {categories === null ? (
            <CircularProgress
              style={{
                margin: "auto",
                display: "flex",
                height: "100%",
              }}
            />
          ) : (
            <Fragment>
              <TableContainer
                style={{
                  height: "calc(100% - 50px)",
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Order Id</TableCell>
                      <TableCell>Customer</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Order Item</TableCell>
                      <TableCell>Total Price</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mockOrders
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <Fragment key={row.id}>
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                              onClick={() => navigate("/order-detail")}
                            >
                              <TableCell>
                                <Checkbox />
                              </TableCell>
                              <TableCell>{row.id}</TableCell>
                              <TableCell>{row.customerName}</TableCell>
                              <TableCell>{row.orderDate}</TableCell>
                              <TableCell>{row.itemName}</TableCell>
                              <TableCell className="actions-content">
                                {row.price}
                              </TableCell>
                              <TableCell
                                style={{
                                  fontWeight: 800,
                                  color:
                                    row.orderStatus === "Fulfilled"
                                      ? "green"
                                      : "gray",
                                }}
                              >
                                ⬤ {row.orderStatus}
                              </TableCell>
                            </TableRow>
                            {displaySubcategories.includes(row.id) && (
                              <TableRow>
                                <TableCell colSpan={5}>
                                  <Table
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.id}
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Sub Category Name</TableCell>
                                        <TableCell>Registered At</TableCell>
                                        <TableCell>Actions</TableCell>
                                        <TableCell>
                                          <Button
                                            onClick={() => {
                                              setCategoryClicked(
                                                categories.indexOf(row)
                                              );
                                              setDrawerState(3);
                                              setShowDrawer(true);
                                            }}
                                          >
                                            Add Sub-Category
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {row.sub_categories &&
                                        row.sub_categories.map((sc) => {
                                          return (
                                            <TableRow
                                              role="checkbox"
                                              tabIndex={-1}
                                              key={sc.id}
                                            >
                                              <TableCell>
                                                <IconButton />
                                              </TableCell>
                                              <TableCell className="name-content">
                                                {sc.image ? (
                                                  <img
                                                    className="company-img"
                                                    alt="org"
                                                    src={`https://api.sadashrijewelkart.com/assets/${sc.image}`}
                                                  />
                                                ) : (
                                                  <img
                                                    className="company-img"
                                                    alt="default"
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/assets/logoNew.png"
                                                    }
                                                  />
                                                )}

                                                {sc.name}
                                              </TableCell>
                                              <TableCell>
                                                {sc.created_at}
                                              </TableCell>
                                              <TableCell className="actions-content">
                                                <Edit
                                                  className="allow"
                                                  onClick={() => {
                                                    setDrawerState(4);
                                                    setNewCategoryName(sc.name);
                                                    setCategoryClicked(
                                                      categories.indexOf(row)
                                                    );
                                                    setSubCategoryClicked(
                                                      row.sub_categories.indexOf(
                                                        sc
                                                      )
                                                    );
                                                    setShowDrawer(true);
                                                  }}
                                                />
                                                <Delete
                                                  className="delete"
                                                  onClick={() =>
                                                    deleteSubCategory(sc.id)
                                                  }
                                                />
                                              </TableCell>
                                              <TableCell>
                                                <IconButton />
                                              </TableCell>
                                            </TableRow>
                                          );
                                        })}
                                    </TableBody>
                                  </Table>
                                </TableCell>
                              </TableRow>
                            )}
                          </Fragment>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100, 200]}
                component="div"
                count={categories.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Fragment>
          )}
        </Paper>
      </ThemeProvider>
    </div>
  );
};

const itemData = [
  {
    img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    title: "Breakfast",
    rows: 2,
    cols: 2,
  },
  {
    img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    title: "Burger",
  },
  {
    img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
    title: "Camera",
  },
  {
    img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
    title: "Coffee",
    cols: 2,
  },
  {
    img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
    title: "Hats",
    cols: 2,
  },
  {
    img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
    title: "Honey",
    author: "@arwinneil",
    rows: 2,
    cols: 2,
  },
  {
    img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
    title: "Basketball",
  },
  {
    img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
    title: "Fern",
  },
  {
    img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
    title: "Mushrooms",
    rows: 2,
    cols: 2,
  },
  {
    img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
    title: "Tomato basil",
  },
  {
    img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
    title: "Sea star",
  },
  {
    img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
    title: "Bike",
    cols: 2,
  },
];

export default OrdersComponent;

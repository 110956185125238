import React, { Fragment, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Divider,
  Paper,
  TableContainer,
  Avatar,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  createTheme,
  ThemeProvider,
  CircularProgress,
  SwipeableDrawer,
  Button,
  IconButton,
  Box,
  Typography,
  Rating,
  ImageListItem,
  ImageList,
} from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import {
  Search,
  Edit,
  Delete,
  Add,
  AddAPhoto,
  Done,
  Restore,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import axios from "axios";

import "./review.styles.scss";

import InputTextField from "../input-text-field/input-text-field.component";
import { generalToastStyle } from "../../utils/toast.styles";
import FlagIcon from "@mui/icons-material/Flag";

const theme = createTheme({
  palette: {
    primary: {
      main: "#a36e29",
    },
  },
  typography: {
    fontFamily: '"Work Sans", sans-serif',
  },
});

function srcset(image, size, rows = 1, cols = 1) {
  console.log(`https://api.sadashrijewelkart.com/assets/${image}`);
  return {
    src: `https://api.sadashrijewelkart.com/assets/${image}`,
  };
}
const ReviewComponent = ({ row }) => {
  const admin = JSON.parse(localStorage.getItem("admin"));
  const [searchText, setSearchText] = useState();

  const [newCategoryImage, setNewCategoryImage] = useState();
  const [newCategoryName, setNewCategoryName] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const [drawerState, setDrawerState] = useState(1);
  const [displaySubcategories, setDisplayCategories] = useState([]);
  const [selectedReview, setSelectedReview] = useState({});

  const [categories, setCategories] = useState(null);
  const [page, setPage] = useState(0);
  const [categoryClicked, setCategoryClicked] = useState(0);
  const [subCategoryClicked, setSubCategoryClicked] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showDrawer, setShowDrawer] = useState(false);
  const handleFlagReview = (targetId) => {
    axios
      .post(
        "https://api.sadashrijewelkart.com/v1.0.0/admin/product/reviews.php",
        {
          type: "flag_reviews",
          id: targetId,
          flag_review: true,
          flag_reason: "Inappropriate Review",
        },
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setCategories(response.data.response);
      })
      .catch((error) => {
        console.log(error);
        toast.warn(error.response.data.message, generalToastStyle);
      });
  };

  const getCategories = () => {
    axios
      .get(
        "https://api.sadashrijewelkart.com/v1.0.0/admin/product/category/all.php",
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setCategories(response.data.response);
      })
      .catch((error) => {
        console.log(error);
        toast.warn(error.response.data.message, generalToastStyle);
      });
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rowClicked = (row) => {
    const a = row.image_url.split('","');
    console.log("here is a", a[0].slice(2));
    setSelectedReview(row);
    setCategoryClicked(categories.indexOf(row));
    setShowDrawer(true);
  };

  // Add a sub-category
  const addSubCategory = () => {
    if (
      newCategoryName === null ||
      newCategoryName === "" ||
      typeof newCategoryName === "undefined"
    ) {
      toast.warn("Syb Category Name is required!", generalToastStyle);
    } else {
      setShowLoading(true);
      let formData = new FormData();
      formData.append("type", "sub-category");
      formData.append("category", categories[categoryClicked]["id"]);
      if (newCategoryImage !== null) formData.append("image", newCategoryImage);
      formData.append("name", newCategoryName);

      axios
        .post(
          "https://api.sadashrijewelkart.com/v1.0.0/admin/product/category/add.php",
          formData,
          {
            headers: {
              Authorization: `Bearer ${admin.token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((_) => {
          setShowLoading(false);
          setNewCategoryImage(null);
          setNewCategoryName("");
          setShowDrawer(false);
          getCategories();
          toast("New Sub Category added successfully!", generalToastStyle);
        })
        .catch((err) => {
          setShowLoading(false);
          toast.error(err.response.data.message, generalToastStyle);
        });
    }
  };

  const deleteCategory = (id) => {
    console.log(`Bearer ${admin.token}`);
    axios
      .delete(
        "https://api.sadashrijewelkart.com/v1.0.0/admin/product/category/delete.php",
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
            "Content-Type": "application/json",
          },
          data: {
            type: "category",
            id,
          },
        }
      )
      .then((_) => {
        getCategories();
        toast("Category deleted successfully!", generalToastStyle);
      })
      .catch((err) => {
        setShowLoading(false);
        toast.error(err.response.data.message, generalToastStyle);
      });
  };

  // Delete a sub category
  const deleteSubCategory = (id) => {
    console.log(`Bearer ${admin.token}`);
    axios
      .delete(
        "https://api.sadashrijewelkart.com/v1.0.0/admin/product/category/delete.php",
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
            "Content-Type": "application/json",
          },
          data: {
            type: "sub-category",
            id,
          },
        }
      )
      .then((_) => {
        getCategories();
        toast("Sub Category deleted successfully!", generalToastStyle);
      })
      .catch((err) => {
        setShowLoading(false);
        toast.error(err.response.data.message, generalToastStyle);
      });
  };

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="category-component">
      <ToastContainer />

      {/* Swipable Drawer */}
      <ThemeProvider theme={theme}>
        <SwipeableDrawer
          anchor="right"
          open={showDrawer}
          onClose={() => {
            setShowLoading(false);

            setShowDrawer(false);
          }}
          onOpen={() => setShowDrawer(true)}
        >
          <div
            style={{
              padding: "20px",
              marginTop: "20px",
              marginLeft: "20px",
            }}
          >
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "600",
                fontFamily: '"Work Sans", sans-serif',
                marginBottom: "40px",
              }}
            >
              View User Review
            </div>
            <Box
              style={{
                maxWidth: "30vw",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "2%",
                }}
              >
                <Avatar sx={{ bgcolor: "gray" }} alt="Gemy Sharp">
                  G
                </Avatar>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    marginLeft: "2%",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#606060",
                      fontSize: "1rem",
                    }}
                  >
                    {selectedReview?.userName || "Gordon Ramsey"}
                  </Typography>
                  <Typography style={{ color: "#a0a0a0" }}>
                    {selectedReview?.created_at}
                  </Typography>
                </Box>
              </Box>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Rating
                  name="size-large"
                  value={parseInt(selectedReview?.rating || 2)}
                  size="large"
                  readOnly
                />
                <Typography style={{ fontSize: "1.4rem" }}>
                  {selectedReview?.rating}/5
                </Typography>
              </Box>
              <Typography
                variant="subtitle"
                style={{
                  textAlign: "justify",
                  marginTop: "10%",
                  marginBottom: "5%",
                }}
              >
                Receive ₹200 worth xCLusive Points by writing a review and
                uploading a picture of your jewellery
              </Typography>

              <ImageList
                sx={{ width: "100%", height: 400 }}
                variant="quilted"
                cols={4}
                rowHeight={121}
              >
                {selectedReview?.image_url?.split('","').map((item) => (
                  <ImageListItem key={item} cols={1} rows={1}>
                    <img
                      {...srcset(item?.slice(2), 121, 0, 0)}
                      alt={item.title}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Box>
          </div>
        </SwipeableDrawer>
      </ThemeProvider>

      {/* Main Content */}
      <div className="head">
        <div className="head-txt">Reviews</div>
      </div>
      <div className="secondary-div">
        <div className="secondary-content">
          <p>
            <span className="selected-text">{row.length || 0} Reviews(s) </span>
          </p>
        </div>
        <div class="input-container">
          <Search class="icon" />
          <input
            class="input-field"
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
          />
        </div>
      </div>
      <Divider />

      <ThemeProvider theme={theme}>
        <Paper className="table-paper">
          {categories === null ? (
            <CircularProgress
              style={{
                margin: "auto",
                display: "flex",
                height: "100%",
              }}
            />
          ) : (
            <Fragment>
              <TableContainer
                style={{
                  height: "calc(100% - 50px)",
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>User Name</TableCell>
                      <TableCell>Rating</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Review</TableCell>
                      <TableCell>Images</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.map((row) => {
                      return (
                        <Fragment key={row.id}>
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                          >
                            <TableCell onClick={() => rowClicked(row)}>
                              {row.user_name || ""}
                            </TableCell>
                            <TableCell onClick={() => rowClicked(row)}>
                              {row.user_name || ""}
                            </TableCell>
                            <TableCell onClick={() => rowClicked(row)}>
                              {row.rating}/5{" "}
                            </TableCell>
                            <TableCell onClick={() => rowClicked(row)}>
                              {row.email || ""}
                            </TableCell>
                            <TableCell onClick={() => rowClicked(row)}>
                              {row.content}
                            </TableCell>
                            <TableCell onClick={() => rowClicked(row)}>
                              {row.email || ""}
                            </TableCell>
                            <TableCell className="actions-content">
                              <FlagIcon
                                className="allow"
                                onClick={() => {
                                  handleFlagReview(row.id);
                                }}
                              />
                              <Delete
                                className="delete"
                                onClick={() => deleteCategory(row.id)}
                              />
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100, 200]}
                component="div"
                count={row.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Fragment>
          )}
        </Paper>
      </ThemeProvider>
    </div>
  );
};

const itemData = [
  {
    img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    title: "Breakfast",
    rows: 2,
    cols: 2,
  },
  {
    img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    title: "Burger",
  },
  {
    img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
    title: "Camera",
  },
  {
    img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
    title: "Coffee",
    cols: 2,
  },
  {
    img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
    title: "Hats",
    cols: 2,
  },
  {
    img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
    title: "Honey",
    author: "@arwinneil",
    rows: 2,
    cols: 2,
  },
  {
    img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
    title: "Basketball",
  },
  {
    img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
    title: "Fern",
  },
  {
    img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
    title: "Mushrooms",
    rows: 2,
    cols: 2,
  },
  {
    img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
    title: "Tomato basil",
  },
  {
    img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
    title: "Sea star",
  },
  {
    img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
    title: "Bike",
    cols: 2,
  },
];

export default ReviewComponent;

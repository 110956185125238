import React from "react";
import { Divider } from "@mui/material";
import { useParams } from "react-router-dom";

import "./home.styles.scss";

import CustomDrawer from "../../components/drawer/drawer.component";

const Home = () => {
  let { section } = useParams();

  return (
    <div className="home">
      <CustomDrawer section={"dashboard"} />
      <Divider orientation="vertical" className="divider" />
      <div className="component">
          <div>empty</div>
      </div>
    </div>
  );
};

export default Home;
